@import "/src/variables";
@import "/src/index.scss";

.product-details-main {
    .section-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: $--my-black;
        background: url("../../Assets/images/product-detail-banner.png")
            $--my-black;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 300px;

        .text {
            padding: 40px 110px;
            color: #f1f1f1;
            width: 50%;
            min-height: 300px;
            background-color: $--my-black;
            h1 {
                font-size: 48px;
                font-weight: 700;
                text-transform: uppercase;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 24px;
            }
            .statistics {
                display: flex;
                gap: 24px;
                flex-wrap: wrap;
                text-transform: capitalize;
                h2 {
                    font-size: 36px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }
                p {
                    font-weight: 600;
                    font-size: 14px;
                    margin: 0;
                }
            }
        }
        // .banner-background{
        //     height: 400px;
        //     width: 50%;
        //     background-color: white;
        // }
        // img {
        //     min-height: 100%;
        //     max-width: 50%;
        // }
    }
    .section-2 {
        padding: 40px 0px;
        width: 1250px;
        margin: 0 auto;
        color: $--my-black;
        display: flex;
        flex-direction: column;
        gap: 24px;
        p {
            margin: 0;
        }
        .price {
            display: flex;
            align-items: center;
            font-size: 36px;
            font-weight: 600;
            .arrow{
                margin-right: 16px;
            }
            .astric{
                font-size: 24px;
                align-self: flex-start;
            }
        }
        .title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .description {
            color: #475467;
            font-size: 16px;
            font-weight: 400;
        }
        .eligibility {
            display: flex;
            flex-direction: column;
            gap: 12px;

        }
        .my-list {
            display: flex;
            flex-direction: column;
            gap: 6px;
            .point {
                display: flex;
                gap: 6px;
                color: $--secondary-font-color;
            }
            li{
                color: $--secondary-font-color;
            }
        }
        .my-list-loading{
            height: 200px;
            width: min(100%, 800px);
        }
        .buttons {
            display: flex;
            gap: 12px;
            button {
                width: 320px;
            }
            .loading-btn{
                width: 320px;
                height: 50px;
            }
        }
        .note {
            p {
                margin-bottom: 12px;
            }
        }
        .faqs {
            display: flex;
            flex-direction: column;
            gap: 12px;
            color: $--my-black;
            font-weight: 600;

            .each-faq {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                padding: 10px;
                background: #f1f1f1;
                cursor: pointer;
                span {
                    color: $--my-red;
                }
            }
            .each-faq-ans {
                padding: 10px 16px;
                background: #f1f1f1;
                border-left: 2px solid $--my-red;
            }
        }
        .recommendation-services {
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            h1 {
                margin: 0;
                font-size: 40px;
                font-weight: 600;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                color: $--secondary-font-color;
            }
        }
    }
    .section-container{
        width: 1250px;
        margin: 0 auto;
    }
    .section-3 {
        background: #f9fafb;
        padding: 60px 0;
        .section-container {
            h1 {
                color: $--my-black;
                font-size: 36px;
                font-weight: 700;
            }
            .description {
                color: $--secondary-font-color;
                font-size: 20px;
                font-weight: 400;
            }
            .case-study-container {
                display: flex;
                gap: 25px;
                overflow-x: hidden;
                scroll-behavior: smooth;
                margin-bottom: 30px;
                // pointer-events: none;
                .case-study {
                    min-width: 400px;
                    max-width: 400px;
                    height: fit-content;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    img {
                        height: 250px;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                    .title {
                        color: $--my-black;
                        font-size: 24px;
                        margin: 0;
                    }
                    .about {
                        font-size: 14px;
                        font-weight: 400;
                        color: $--secondary-font-color;
                        margin: 0;
                    }
                    .read {
                        font-size: 14px;
                        font-weight: 600;
                        color: $--my-red;
                        text-transform: capitalize;
                        text-align: right;
                        cursor: pointer;
                        margin: 0;
                    }
                }
            }
            .case-study-container::-webkit-scrollbar {
                width: 0px;
            }
            .pagination{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 20px;
                
                .pagination-item{
                    height: 16px;
                    width: 16px;
                    background-color: #B9B9B9;
                    border-radius: 50%;
                    outline-offset: 4px;
                    cursor: pointer;
                }
                .pagination-item:hover{
                    outline: 2px solid $--my-red;
                    background-color: $--my-red;
                }
                .activated{
                    outline: 2px solid $--my-red;
                    background-color: $--my-red;
                }
            }
        }
    }
    .section-4{
        background: $--my-black;
        .section-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 24px;
            p{
                color: #f1f1f1;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
                text-align: center;
            }
            .client-container{
                display: flex;
                justify-content: center;
                .client-logo-swiper {
                    .swiper-wrapper {
                        margin-left: 50%;
                    }
                }
            }
        }
    }
}
@media (max-width: 1300px){
    .product-details-main{
        .section-2, .section-container{
            width: 950px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 300px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px){
    .product-details-main{
        .section-1{
            .text{
                padding: 40px 55px;
            }
        }
        .section-2, .section-container{
            width: 800px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 250px;
                    }
                }
            }
        }
        .section-4{
            .section-container{
                .client-container{
                    .client-logo-swiper{
                        width: 90vw;
                    }
                }
            }
        }
    }
}
@media (max-width: 850px){
    .product-details-main{
        .section-1{
            .text{
                padding: 40px 55px;
                h1{
                    font-size: 40px;
                }
            }
        }
        .section-2, .section-container{
            width: 650px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 200px;
                    }
                }
            }
        }
    }
}
@media (max-width: 700px){
    .product-details-main{
        .section-1{
            .text{
                width: 100%;
            }
        }
        .section-2, .section-container{
            width: 525px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 250px;
                    }
                }
            }
        }
    }
}
@media (max-width: 550px){
    .product-details-main{
        .section-2, .section-container{
            width: 425px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 200px;
                    }
                }
            }
        }
    }
}
@media (max-width: 450px){
    .product-details-main{
        .section-1{
            .text{
                padding: 40px 20px;
            }
        }
        .section-2, .section-container{
            width: 350px;
            .buttons{
                flex-direction: column;
                button{
                    width: 100%;
                }
            }
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 350px;
                    }
                }
                .pagination{
                    .pagination-item{
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }
    }
}
@media (max-width: 400px){
    .product-details-main{
        .section-1{
            .text{
                h1{
                    font-size: 36px;
                }
                .statistics{
                    h2{
                        font-size: 32px;
                    }
                } 
            }
        }
        .section-2, .section-container{
            width: 300px;
            .recommendation-services{
                h1{
                    font-size: 32px;
                }
            }
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 300px;
                    }
                }
                .description{
                    font-size: 14px;
                }
            }
        }
    }
}
@media (max-width: 350px){
    .product-details-main{
        .section-2, .section-container{
            width: 275px;
        }
        .section-3{
            .section-container{
                .case-study-container{
                    .case-study{
                        min-width: 275px;
                    }
                }
            }
        }
        .section-4{
            .section-container{
                p{
                    font-size: 14px;
                }
            }
        }
    }
}