@import "/src/variables";
@import "/src/index.scss";

.change-password-pop-up-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
    width: 320px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10);

    .cross {
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .heading {
        h3 {
            color: var(--gray-900, #101828);
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        p {
            margin: 0;
            color: var(--gray-600, #475467);
            font-size: 14px;
            font-weight: 400;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .input-grp {
            display: flex;
            flex-direction: column;
            label {
                color: #344054;
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
            .password-input {
                display: flex;
                align-items: center;
                padding-right: 12px;
                border-radius: var(--spacing-0, 4px);
                border: 1px solid #d0d5dd;
                input {
                    border-radius: var(--spacing-0, 4px);
                    background: var(--white, #fff);
                    padding: 12px var(--spacing-2, 16px);
                    outline: none;
                    width: 100%;
                    border: none;
                }
            }
        }
    }
}
