@import "/src/variables";
@import "/src/index.scss";

.chat-window {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: min(100%, 500px);
    height: 100%;
    position: relative;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    .messages {
        display: flex;
        flex-direction: column;
        // gap: 10px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        scroll-behavior: smooth;
        position: relative;
        
        .message-container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            position: relative;
            .message {
                display: flex;
                flex-direction: column;
                gap: 2px;
                width: fit-content;
                max-width: 80%;
                .content {
                    padding: 10px;
                    border-radius: 0px 6px 6px 6px;
                    color: $--my-black;
                    font-size: 14px;
                    font-weight: 400;
                }
                * {
                    white-space: initial;
                }
            }
            .sender, .default{
                align-self: flex-end;
                margin-right: 10px;
                .content {
                    background: #f9f9f9;
                }
            }
            .reciver{
                margin-left: 10px;
                background: #edeeef;
            }
            .default{
                align-self: flex-start;
            }
            .sender.default ~ .icons{
                visibility: hidden;
            }
            .icons {
                margin-left: 10px;
                display: flex;
                width: fit-content;
                padding: 4px 10px;
                gap: 4px;
                background: #edeeef;
                visibility: hidden;
                border-radius: 8px;

                svg {
                    height: 16px;
                    width: 16px;
                    cursor: pointer;
                }
            }
            .Liked{
                .like{
                    path{
                        fill: $--my-red;
                    }
                }
            }
            .DisLiked{
                .dislike{
                    path{
                        fill: black;
                    }
                }
            }
        }
        .message-container:hover{
            .reciver ~ .icons{
                visibility: visible;
            }
        }
    }
    .animation{
        width: 100%;
        height: 100%;
        bottom: 0;
        position: absolute;
    }
    .send-message {
        display: flex;
        align-items: center;
        padding: 12px 0 0 0;
        width: 100%;

        .message-text {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 0 var(--spacing-2, 16px);
            border-radius: 6px;
            border: 1px solid var(--eaebf-0, #eaebf0);
            background: #e9e9e9;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
            width: 100%;

            textarea {
                padding: 12px 0;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 600;
                color: #787878;
                width: 100%;
                height: 100%;
                resize: none;
                background-color: transparent;
            }
            textarea::-webkit-scrollbar {
                display: none;
            }
            span {
                cursor: pointer;
            }
        }

        .btn-red {
            padding: 4px;
        }
    }
}
.messages::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.messages::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

/* Handle */
.messages::-webkit-scrollbar-thumb {
    background: #828282;
    border-radius: 10px;
}

/* Handle on hover */
.messages::-webkit-scrollbar-thumb:hover {
    background-color: #4b4a4a;
}
@media (max-width: 1100px) {
    .chat-window {
        // display: none;
        .profile-section {
            padding: 12px;
        }
    }
}

@media (max-width: 1000px) {
    .chat-window {
        .messages{
            .message-container{
                .reciver ~ .icons{
                    visibility: visible;
                }
            }
        }
    }
}