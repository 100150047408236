@import "/src/variables";
@import "/src/index.scss";

.order-page {
    display: flex;
    flex-direction: column;
    padding: 60px 110px;

    .order-main {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        padding: var(--spacing-3, 24px);
        padding-bottom: 0;
        border-radius: 6px;
        border: 1px solid var(--color-outline-outline-2, #c7c7cd);

        hr {
            margin: 0;
        }
        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                color: $--my-black;
                font-size: 40px;
                font-weight: 600;
                margin: 0;
            }
            p {
                color: $--my-black;
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }
        }
        .order-items {
            display: flex;
            flex-direction: column;
            // gap: 20px;

            .product-info {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                gap: 20px;
                padding: 16px 0;
                border-top: 1px solid #c7c7cd;

                .product-name {
                    display: flex;
                    // justify-content: space-between;
                    gap: 12px;
                    img {
                        height: 50px;
                        width: 50px;
                        border: 1px solid $--secondary-font-color;
                    }
                    h3 {
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 600;
                        width: 120px;
                    }
                }
                .category{
                    display: flex;
                    flex-direction: column;
                    p{
                        margin: 0;
                    }
                    .title{
                        font-weight: 600;
                        text-wrap: nowrap;
                    }
                }
                .price {
                    p {
                        color: $--my-black;
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    .remove {
                        text-align: right;
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .order-page {
        padding: 30px 90px;
    }
}
@media (max-width: 850px) {
    .order-page {
        padding: 30px;
        .order-main{
            .order-items{
                .product-info{
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
    }
}
@media (max-width: 700px) {
    .order-page {
        padding: 20px;
        .order-main {
            .order-items {
                .product-info {
                    flex-wrap: wrap;
                }
            }
        }
    }
}
@media (max-width: 500px) {
    .order-page {
        .order-main{
            .order-items{
                .product-info{
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .order-page {
        .order-main{
            .order-items{
                .product-info{
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}