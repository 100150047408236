@import "/src/variables";
@import "/src/index.scss";

.dashboard-main {
    .dashboard-body {
        .empty-list{
            text-align: center;
            margin: 0;
            font-size: 20px;
            color: $--secondary-font-color;
            margin: auto;
        }
        .main-content {
            display: flex;
            flex-direction: column;
            gap: 30px;
            h1 {
                font-weight: 600;
                font-size: 40px;
                line-height: 44px;
                color: $--my-black;
            }
            .tag-line {
                margin: 0;
                color: $--secondary-font-color;
            }
            .meeting-container {
                padding: 24px;
                color: $--my-black;
                background-color: $--background-color;
                border-radius: var(--spacing-1, 8px);
                border: 1px solid var(--gray-200, #eaecf0);
                display: flex;
                flex-direction: column;
                gap: 24px;

                .heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h3 {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 24px;
                        font-weight: 600;
                    }
                    select {
                        padding: 6px 12px;
                        border: 1px solid
                            var(--color-outline-outline-2, #c7c7cd);
                        border-radius: 4px;
                        background: #fff;
                        outline: none;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }

                .meetings-list {
                    display: flex;
                    gap: 16px;
                    overflow-x: scroll;

                    .meeting-card {
                        border-radius: 8px;
                        border: 1px solid var(--gray-200, #eaecf0);
                        padding: 24px;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        min-width: 350px;
                        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

                        .product-details{
                            .topic-name {
                                color: var(--gray-900, #101828);
                                font-size: 16px;
                                font-weight: 600;
                                margin: 0;
                            }
                            ul{
                                margin-top: 4px;
                                padding-left: 1.5rem;
                                li{
                                    color: $--secondary-font-color;
                                    font-size: 12px;
                                }
                            }
                        }
                        .meeting-details {
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            p {
                                margin: 0;
                                color: $--secondary-font-color;
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .bold {
                                font-weight: 700;
                                color: $--my-black;
                            }
                            .grp {
                                display: flex;
                                flex-direction: column;
                                gap: 6px;
                                .gmeet-title {
                                    display: flex;
                                    gap: 6px;
                                }
                                a{
                                    text-decoration: none;
                                }
                                .link {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding: 12px;
                                    background-color: #F1F1F1;
                                    width: 100%;
                                    border-radius: var(--spacing-0, 4px);
                                    cursor: pointer;

                                    p{
                                        margin: 0;
                                        font-size: 13px;
                                        color: $--secondary-font-color;
                                    }
                                    .arrow {
                                        min-height: 20px;
                                        min-width: 20px;
                                        background: white;
                                        display: grid;
                                        place-items: center;
                                        border-radius: 4px;
                                        // transform: rotate(180deg);

                                        svg {
                                            path {
                                                fill: $--my-black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .meetings-list::-webkit-scrollbar {
                    width: 0px;
                }
            }
            .progress-container {
                background: $--background-color;
                border-radius: var(--spacing-1, 8px);
                border: 1px solid var(--gray-200, #eaecf0);
                padding: 24px;
                
                .empty-list{
                    margin-top: 20px;
                }
                .heading{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 8px;
                    h2 {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 24px;
                        color: $--my-black;
                        margin: 0;
                    }
                    div{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: var(--spacing-2, 16px);
                        width: 100%;
                        span{
                            display: flex;
                            gap: 8px;
                            cursor: pointer;
    
                            svg{
                                transform: rotate(90deg);
                                path{
                                    fill: $--secondary-font-color;
                                }
                            }
                            p{
                                margin: 0;
                                font-size: 16px;
                                font-weight: 600;
                                color: $--secondary-font-color;
                            }
                        }
                        select{
                            padding: 6px 12px;
                            outline: none;
                            border-radius: 4px;
                            border: 1px solid var(--color-outline-outline-2, #C7C7CD);
                            background: #FFF;
                            font-weight: 600;
                            width: inherit;
                            max-width: fit-content;
                        }
                    }
                }
                
                .progress-details {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    background: $--background-color;
                    border: 1px solid var(--gray-200, #eaecf0);
                    border-radius: 8px;
                    padding: 24px;
                    margin-top: 24px;
                    color: $--secondary-font-color;
    
                    p {
                        margin: 0;
                    }
    
                    .service-details {
                        .title {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            margin-bottom: 4px;
                            color: $--my-black;
                        }
                        .description{
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
    
                    .time {
                        color: $--my-black;
                        font-size: 14px;
                        font-weight: 400;
                        .start {
                            margin-right: 16px;
                        }
                    }
    
                    .progress-bar {
                        height: 12px;
                        width: 100%;
                        background: #F1F1F1;
                        border-radius: 40px;
    
                        .progress {
                            background-color: #37833b;
                            width: 10%;
                            border-radius: 40px;
                        }
                    }
    
                    .last-update {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                    }
    
                    .work-details {
                        display: flex;
                        justify-content: flex-start;
                        gap: 10px;
    
                        .custom-check-box {
                            display: flex;
                            // align-items: center;
                            justify-content: center;
    
                            .checkmark {
                                height: 30px;
                                width: 30px;
                                display: inline-block;
                                background-color: #F1F1F1;
                                border-radius: 8px;
                                
                                .checked {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;
                                    margin: 0;
                                    border-radius: 8px;
                                }
                            }
                        }
                        .details{
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            font-size: 14px;
                            .topic, .bold{
                                color: $--my-black;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .dashboard-main{
        .dashboard-body{
            .main-content{
                padding: 20px;
                gap: 24px;

                h1{
                    font-size: 32px;
                    margin: 0;
                }

                .meeting-container{
                    padding: 12px;
                    .meetings-list{
                        padding: 12px;
                        .meeting-card{
                            padding: 12px;
                            min-width: 250px;
                            .meeting-details{
                                .grp{
                                    .link{
                                        p{
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .progress-container{
                    padding: 12px;
                    .heading{
                        flex-direction: column;
                        align-items: flex-start;
                        div{
                            flex-direction: column-reverse;
                            align-items: flex-end;
                            width: 100%;
                            gap: 8px;
                            
                            select{
                                width: 100%;
                                font-size: 12px;
                                max-width: unset;
                            }
                        }
                    }
                    .progress-details{
                        padding: 12px;
                        gap: 10px;

                        .service-details{
                            .description{
                                font-size: 10px;
                                
                            }
                        }
                        .time{
                            font-size: 10px;
                        }
                        .last-update{
                            font-size: 10px;
                        }
                        .work-details{
                            .details{
                                font-size: 10px;
                                .topic{
                                    font-size: 14px;
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}