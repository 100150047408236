@import "/src/variables";
@import "/src/index.scss";

.dashboard-main {
    .dashboard-body {
        .main-content {
            .message-main {
                .scroll::-webkit-scrollbar {
                    width: 4px;
                }

                /* Track */
                .scroll::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #ffffff;
                }

                /* Handle */
                .scroll::-webkit-scrollbar-thumb {
                    background: #828282;
                    border-radius: 10px;
                }

                /* Handle on hover */
                .scroll::-webkit-scrollbar-thumb:hover {
                    background-color: #4b4a4a;
                }

                background: $--background-color;
                border: 1px solid var(--2-stork, #d0d5dd);
                border-radius: 8px;
                height: 90vh;
                display: flex;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
                
                .chats {
                    width: 30%;
                    border-right: 1px solid var(--2-stork, #d0d5dd);
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    .heading {
                        display: flex;
                        align-items: center;
                        height: 72px;
                        padding: 12px var(--spacing-3, 24px);
                        border-bottom: 1px solid var(--d-0-d-5-dd, #d0d5dd);

                        h1 {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 24px;
                            color: $--my-black;
                            margin: 0;
                        }
                    }
                    .chat-list {
                        display: flex;
                        flex-direction: column;
                        overflow-y: scroll;
                        height: 100%;
                        .chat {
                            display: flex;
                            gap: 12px;
                            align-items: center;
                            padding: 12px;
                            box-sizing: border-box;
                            border-bottom: 1px solid #C7C7C7;
                            cursor: pointer;
                            img {
                                height: 48px;
                                width: 48px;
                                border-radius: 50%;
                            }
                            .name {
                                p {
                                    margin: 0;
                                    color: var(--grey-110, #0d0e14);
                                    font-size: 16px;
                                }
                                .last-message {
                                    color: var(--grey, #5f6368);
                                    font-size: 12px;
                                    font-weight: 400;
                                }
                            }
                        }
                        .current {
                            border-left: 4px solid
                                var(--color-brand-logo-1, #e31e26);
                            padding-left: 8px;
                        }
                    }
                }

                .chat-window {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    
                    .profile-section {
                        display: flex;
                        align-items: center;
                        padding: 12px 24px;
                        gap: 8px;
                        width: 100%;
                        border-bottom: 1px solid #d0d5dd;
                        height: 78px;
                        overflow: hidden;
                        position: relative;
                        border-top-right-radius: 8px;

                        svg{
                            display: block;
                            height: 20px;
                            width: 20px;
                            cursor: pointer;
                            path{
                                fill: #222222;
                            }
                        }
                        
                        .profile-img {
                            height: 48px;
                            width: 48px;
                            border-radius: 50%;
                            background: #222222;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            
                            img {
                                height: 100%;
                                width: 100%;
                            }
                            .status {
                                height: 11px;
                                width: 11px;
                                border-radius: 50%;
                                position: absolute;
                                right: 2px;
                                bottom: 2px;
                            }
                            .online {
                                background-color: #10c62d;
                            }
                        }

                        .person-detail {
                            p {
                                margin: 2px;
                                color: $--my-black;
                            }

                            .name {
                                font-weight: 600;
                                font-size: 18px;
                            }
                            
                            .role {
                                font-weight: 400;
                                font-size: 14px;
                            }
                        }
                    }
                    .messages {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        width: 100%;
                        height: 100%;
                        overflow-y: scroll;
                        background: #F9F9F9;

                        .message {
                            display: flex;
                            flex-direction: column;
                            gap: 2px;
                            width: fit-content;
                            .content{
                                padding: 10px;
                                background: #EDEEEF;
                                border-radius: 0px 6px 6px 6px;
                                color: $--my-black;
                                font-size: 14px;
                                font-weight: 400;
                            }
                            .time{
                                font-size: 8px;
                                align-self: flex-end;
                            }
                        }
                        .sender{
                            margin-left: 24px;
                        }
                        .reciver{
                            align-self: flex-end;
                            margin-right: 24px;
                            .content{
                                background: white;
                            }
                        }
                    }
                    .send-message {
                        display: flex;
                        align-items: center;
                        padding: 12px 24px;
                        width: 100%;

                        .message-text {
                            display: flex;
                            align-items: center;
                            gap: 12px;
                            padding: 0 var(--spacing-2, 16px);
                            border-radius: 6px;
                            border: 1px solid var(--eaebf-0, #EAEBF0);
                            background: white;
                            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
                            width: 100%;

                            input {
                                padding: 12px 0;
                                border: none;
                                outline: none;
                                font-size: 16px;
                                font-weight: 600;
                                color: #787878;
                                width: 100%;
                            }
                            span{
                                cursor: pointer;
                            }
                        }

                        .btn-red {
                            padding: 4px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px){
    .dashboard-main{
        .dashboard-body{
            .main-content{
                padding: 20px 20px 20px 290px;
                .message-main{
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    height: 95vh;
                    .chats{
                        width: 100%;
                        // display: none;
                        .chat-list{
                            .current {
                                border-left: 4px solid
                                var(--color-brand-logo-1, white);
                            }
                        }
                    }
                    .chat-window{
                        // display: none;
                        .profile-section{
                            padding: 12px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 800px){
    .dashboard-main{
        .dashboard-body{
            .main-content{
                .message-main{
                    height: 84vh;
                }
            }
        }
    }
}