@import "/src/variables";
@import "/src/index.scss";

.offcanvas{
    width: 100% !important;
    
    .btn-close:focus{
        box-shadow: none;
    }
    .offcanvas-header{
        width: 100%;
    }
    
    .offcanvas-body{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0px 20px;
        width: 100%;
        
        .item{
            display: flex;
            align-items: center;
            gap: 12px;
            cursor: pointer;
            p{
                margin: 0;
                font-size: 16px;
                font-weight: 600;
            }
            svg{
                path{
                    stroke: $--my-black;
                }
            }
        }
        .primary-btn{
            border: 1px solid var(--color-brand-logo-1, #E31E26);
            box-shadow: 0px 0px 4px 1px #E31E26 inset;
            width: 100px;
        }
    }
  }