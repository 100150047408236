@import "/src/variables";
@import "/src/index.scss";

.create-support-ticket-main{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: relative;
    width: 500px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10);

    .cross {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .heading {
        h3 {
            color: var(--gray-900, #101828);
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        p {
            margin: 0;
            color: var(--gray-600, #475467);
            font-size: 14px;
            font-weight: 400;
        }
    }
    form{
        *{
            margin: 0
        }
        display: flex;
        flex-direction: column;
        gap: 10px;

        label{
            color: #344054;
            font-size: 16px;
            font-weight: 600;
        }
        textarea{
            padding: 12px var(--spacing-2, 16px);
            border-radius: var(--spacing-0, 4px);
            border: 1px solid #D0D5DD;
            background: white;
            outline: none;
            height: 300px;
            resize: none;
            color: #838799;
            font-size: 16px;
            font-weight: 400;
        }
        textarea::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        textarea::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        /* Handle */
        textarea::-webkit-scrollbar-thumb {
            background: #828282;
            border-radius: 10px;
        }

        /* Handle on hover */
        textarea::-webkit-scrollbar-thumb:hover {
            background-color: #4b4a4a;
        }
    }
}
@media only screen and (max-width: 550px) {
    .create-support-ticket-main {
        width: 400px;
    }
}
@media only screen and (max-width: 450px) {
    .create-support-ticket-main {
        width: 320px;
    }
}