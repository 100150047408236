@import "/src/variables";
@import "/src/index.scss";

.welcome-screen{
    display: flex;
    flex-direction: column;
    h1{
        color: $--my-black;
        font-size: 100px;
        font-weight: 400;
        margin-bottom: 30px;
    }
    h3{
        color: $--my-black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    p{
        color: $--secondary-font-color;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 100px;
    }
    .link{
        border-bottom: 1px solid $--my-black;
        cursor: pointer;
    }
    button{
        width: fit-content;
        align-self: flex-end;
    }
}

@media (max-width: 650px){
    .welcome-screen{
        h1{
            font-size: 52px;
        }
        h3{
            font-size: 32px;
        }
        p{
            font-size: 16px;
        }
    }
}