@import "/src/variables";
@import "/src/index.scss";

.home-main {
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .section {
        .section-container {
            .section-heading {
                color: $--my-black;
                text-transform: capitalize;
                font-weight: 400;
                position: relative;
                margin-left: 10px;
                margin-bottom: 0;
                p{
                    margin-bottom: 0;
                }
                .vertical-text {
                    position: absolute;
                    color: $--my-red;
                    font-weight: 600;
                    transform: rotate(-90deg);
                }
            }
            .loading-section-heading{
                height: 80px;
                width: min(100%, 400px);
                margin-bottom: 20px;
            }
            .animate {
                animation: typeWriter 2s;
                animation-name: typeWriter;
                animation-duration: 1.5s;
                animation-delay: 0.5s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
                animation-timing-function: linear;
                display: inline-block;
                width: 0%;
                overflow: hidden;
                border-right: 1px solid $--my-red;
            }
            @keyframes typeWriter {
                0% {
                    width: 0%;
                }
                100% {
                    width: 100%;
                }
            }
        }
    }
    .section-1 {
        width: 65%;
        margin: 0 auto;
        margin-top: 60px;
        .section-1-container {
            display: flex;
            justify-content: space-between;
            gap: 140px;
            .main-text {
                display: flex;
                flex-direction: column;
                // gap: 8px;

                h1 {
                    font-size: 64px;
                    text-wrap: nowrap;
                }
                .line-1 {
                    .vertical-text {
                        font-size: 12px;
                        top: 70px;
                        left: -70px;
                    }
                }
                .line-2 {
                    display: inline-block;
                    text-wrap: nowrap;
                    animation: businessTagLineAnimation 1.5s ease 0s 1 forwards;
                    transition: all 1s;
                    overflow: hidden;
                    border-right: 1px solid $--my-red;
                    font-size: 64px;
                    margin-left: 10px;
                    text-transform: capitalize;
                    span {
                        color: $--my-red;
                    }
                }
                @keyframes businessTagLineAnimation {
                    0% {
                        width: 0px;
                    }
                    40% {
                        width: 135px;
                    }
                    55% {
                        width: 145px;
                    }
                    85% {
                        width: 415px;
                    }
                    100% {
                        width: 400px;
                    }
                }
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $--secondary-font-color;
                    width: 500px;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    width: 420px;
                    z-index: 0;

                    .call-btn {
                        display: flex;
                        align-items: center;
                        position: relative;
                        margin-top: 55px;
                        .primary-btn {
                            border: 1px solid var(--color-brand-logo-1, #e31e26);
                            box-shadow: 0px 0px 4px 1px #e31e26 inset;
                            transition: all 0.18s ease-in;
                            text-wrap: nowrap;
                            &:before {
                                transition: all 0.18s ease-in;
                            }
                        }
                        .call-icon {
                            background-color: $--my-red;
                            padding: 8px;
                            display: inline-grid;
                            place-items: center;
                            border-radius: 50%;
                            position: absolute;
                            transition: all 0.18s ease-in 0s;
                            left: 0;
                            z-index: -1;
                            svg {
                                path {
                                    fill: #ffffff;
                                }
                            }
                        }
                    }
                    .call-btn:hover > .call-icon {
                        left: calc(100% + 8px);
                    }
                    .my-svg {
                        position: relative;
                        transform: translate(90%, -50%);
                        img {
                            position: absolute;
                        }
                        .animate-SVG {
                            animation: svgAnimation 5.5s ease 0s infinite;
                        }
                        @keyframes svgAnimation {
                            0% {
                                transform: rotate(0deg);
                            }
                            20% {
                                transform: rotate(0deg);
                            }
                            30% {
                                transform: rotate(-5deg);
                            }
                            50% {
                                transform: rotate(-5deg);
                            }
                            60% {
                                transform: rotate(-15deg);
                            }
                            80% {
                                transform: rotate(-15deg);
                            }
                            100% {
                                transform: rotate(0deg);
                            }
                        }
                    }
                }
                .gif {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 260px;
                    transform: translateX(-50%);
                    margin-top: 30px;
                    .HTML-Embed {
                        pointer-events: none;
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                    .play {
                        position: absolute;
                        right: 0;
                        background-color: #ffffff;
                        border-radius: 50%;
                        padding: 8px;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                    }
                }
            }
            .main-text-loading-cmp{
                width: 100%;
                height: 400px;
            }

            .statistics {
                display: inline-flex;
                flex-direction: column;
                gap: 44px;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    gap: 6px;
                    color: $--my-black;

                    .number {
                        font-size: 40px;
                        font-weight: 400;
                    }
                    .text {
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: capitalize;
                    }

                    .statistics-loading-cmp{
                        height: 100px;
                        width: 150px;
                    }
                }
            }
        }
    }
    .section-2 {
        width: 65%;
        margin: 100px auto;
        display: flex;
        // align-self: flex-end;
        // justify-self: flex-end;
        .section-2-container {
            display: flex;
            flex-direction: column;
            // align-items: center;
            // gap: 16px;
            // padding: 50px 24px;
            h1 {
                // margin: auto;
                margin-left: 35%;
                font-size: 40px;
                margin-bottom: 20px !important;
                .vertical-text {
                    font-size: 14px;
                    top: 40px;
                    left: -50px;
                }
                .bold {
                    max-width: 290px;
                    text-wrap: nowrap;
                }
            }
            .client-container{
                display: flex;
                justify-content: center;
                width: 100%;
                .client-logo-swiper {
                    .swiper-wrapper {
                        margin-left: 35%;
                    }
                }
                .loading-client-logo-container{
                    height: 85px;
                    width: 100%;
                    overflow-x: hidden;
                    display: flex;
                    gap: 20px;

                    .loading-client{
                        height: 100%;
                        min-width: 85px;
                    }
                }
            }
            // .client-logo-swiper {
            //     // display: none;
            //     // width: 80vw;
            //     // margin: auto;
            //     // z-index: 0;
            //     // position: relative;
            //     // // margin-left: 20%;
                
            //     // .swiper-wrapper {
            //     //     margin: auto;
            //     //     width: fit-content;
            //     //     align-items: center;
            //     //     .client{
            //     //         height: 85px;
            //     //         width: 85px;
            //     //         border-radius: 5px;
            //     //         border: 0.487px solid #d6d6d6;
            //     //         display: flex;
            //     //         align-items: center;
            //     //         justify-content: center;
            //     //         // padding: 10px;
            //     //         background-color: #F7F7F7;
                        
            //     //         img {
            //     //             max-width: 100%;
            //     //             width: 75px;
            //     //             height: 75px;
            //     //             max-height: 100%;
            //     //             object-fit: contain;
            //     //             mix-blend-mode: multiply;
            //     //         }
            //     //     }
            //     // }
            // }
            // .client-logo-container {
            //     display: flex;
            //     // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            //     gap: 24px;
            //     flex-wrap: wrap;

            //     .client{
            //         height: 100px;
            //         width: 150px;
            //         border-radius: 5px;
            //         border: 0.487px solid #d6d6d6;
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //         // padding: 10px;
            //         background-color: #F7F7F7;
                    
            //         img {
            //             max-width: 100%;
            //             width: 120px;
            //             height: 70px;
            //             max-height: 100%;
            //             object-fit: contain;
            //             mix-blend-mode: multiply;
            //         }
            //     }
            // }
        }
    }
    .section-3 {
        margin: auto;
        display: flex;
        // justify-content: center;
        width: 65%;
        .section-3-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
            width: 100%;
            h1 {
                font-size: 40px;
                p {
                    margin: 0;
                }
                .vertical-text {
                    font-size: 14px;
                    top: 44px;
                    left: -44px;
                }
                .bold {
                    max-width: 205px;
                    text-wrap: nowrap;
                }
            }
            .stratergies {
                display: flex;
                gap: 40px;
                width: 600px;

                .stratergy {
                    display: flex;
                    flex-direction: column;
                    color: $--my-black;
                    gap: 16px;
                    .title {
                        p {
                            font-size: 24px;
                            margin: 0;
                            text-transform: capitalize;
                        }
                        .red {
                            font-size: 36px;
                            color: $--my-red;
                        }
                    }
                    .content {
                        font-size: 16px;
                        font-weight: 400;
                        margin: 0;
                    }
                }
                .loading-strat{
                    height: 250px;
                    width: 200px;
                }
            }
        }
    }
    .logo-bg {
        width: 110vw;
        height: 410px;
        margin-top: 100px;
        margin-bottom: 100px;
        background: url("../../Assets/home/logo-bg.svg");
        background-size: contain;
        background-size: 200%;
        transform: rotate(-15deg);
        animation: logo-bg-animation 6s linear 0s infinite forwards;
        background-repeat: no-repeat;
        position: relative;
        right: 80px;
        img {
            overflow: hidden;
            height: 100%;
            width: 100vw;
        }
    }
    @keyframes logo-bg-animation {
        0% {
            background-position-x: 100%;
        }
        // 10% {
        //     background-position-x: 0%;
        // }
        // 33% {
        //     background-position-x: 50%;
        // }
        // 43% {
        //     background-position-x: 50%;
        // }
        // 66% {
        //     background-position-x: 100%;
        // }
        // 76% {
        //     background-position-x: 100%;
        // }
        100% {
            background-position-x: 0%;
        }
    }
    .section-4 {
        margin: 100px auto;
        width: 65%;
        display: flex;
        // justify-content: center;

        .section-4-container {
            display: flex;
            flex-direction: row-reverse;
            gap: 16px;
            width: 1000px;
            img {
                height: 400px;
                width: 400px;
                transform: rotateY(180deg);
            }
            .loading-about-us-img{
                height: 100%;
                width: 100%;
            }
            .content {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
                h1 {
                    font-size: 40px;
                    .vertical-text {
                        font-size: 12px;
                        top: 40px;
                        left: -44px;
                    }
                    .bold {
                        max-width: 220px;
                        text-wrap: nowrap;
                    }
                }
                .call-btn {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 216px;
                    .primary-btn {
                        border: 1px solid var(--color-brand-logo-1, #e31e26);
                        box-shadow: 0px 0px 4px 1px #e31e26 inset;
                        transition: all 0.18s ease-in;
                        text-wrap: nowrap;
                        &:before {
                            transition: all 0.18s ease-in;
                        }
                    }
                    .call-icon {
                        background-color: $--my-red;
                        padding: 8px;
                        display: inline-grid;
                        place-items: center;
                        border-radius: 50%;
                        position: absolute;
                        transition: all 0.18s ease-in 0s;
                        left: 0;
                        z-index: -1;
                        svg {
                            path {
                                fill: #ffffff;
                            }
                        }
                    }
                }
                .call-btn:hover > .call-icon {
                    left: 100%;
                }

                .loading-about-us-content{
                    height: 300px;
                    width: 400px;
                }
            }
        }
    }
    .section-5 {
        width: 65%;
        margin: 50px auto;
        display: flex;
        // justify-content: flex-end;
        .section-5-container {
            display: flex;
            flex-direction: column;
            // gap: 20px;
            width: 100%;
            margin-right: 30px;
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h1 {
                    font-size: 40px;
                    p {
                        margin: 0;
                    }
                    .vertical-text {
                        font-size: 12px;
                        top: 40px;
                        left: -50px;
                    }
                    .bold {
                        max-width: 225px;
                        margin: 0;
                        text-wrap: nowrap;
                    }
                }
                .arrows {
                    .reverse {
                        transform: rotate(-180deg);
                        margin-left: 10px;
                    }
                }
            }
            .categories-drop-down {
                display: none;
                flex-direction: column;
                font-size: 12px;
                .selected {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 4px;
                    border: 1px solid #c7c7cd;
                    padding: 6px 12px;
                    p {
                        margin: 0;
                        color: $--my-red;
                        font-weight: 600;
                    }
                }
                .categories {
                    display: none;
                }
            }
            .open {
                .selected {
                    img {
                        transform: rotate(180deg);
                    }
                    border-bottom: none;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
                .categories {
                    border: 1px solid #c7c7cd;
                    border-top: none;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                    display: block;
                    .category {
                        padding: 4px 12px;
                        color: $--secondary-font-color;
                        cursor: pointer;
                    }
                }
            }
            .product-container {
                display: flex;
                // justify-content: space-between;
                gap: 8px;
                .product-categories {
                    display: flex;
                    flex-direction: column;
                    height: 350px;
                    overflow-y: scroll;
                    gap: 20px;
                    overflow-x: hidden;
                    min-width: 200px;
                    width: 200px;
                    margin-right: 20px;
                    .prod-category {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        font-weight: 700;
                        padding-right: 20px;
                        border-bottom: 1px solid var(--2-a-292-a, #2a292a);
                        cursor: pointer;
                        p {
                            margin: 0;
                        }
                        img {
                            display: none;
                        }
                    }
                    .selected-category,
                    .prod-category:hover {
                        border-color: $--my-red;
                        p {
                            color: $--my-red;
                        }
                        img {
                            display: block;
                        }
                    }
                }
                .product-categories::-webkit-scrollbar {
                    width: 0px;
                }
                .products-container {
                    display: flex;
                    justify-content: flex-start;
                    gap: 20px;
                    overflow-x: scroll;
                    scroll-behavior: smooth;
                    height: 370px;
                    .product {
                        min-width: 250px;
                        max-width: 250px;
                        border-radius: 24px;
                        box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        height: 350px;
                        // height: fit-content;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        cursor: pointer;
                        // padding-bottom: 14px;
                        img {
                            height: 150px;
                            width: 100%;
                        }
                        .content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 200px;
                            .text {
                                display: flex;
                                flex-direction: column;
                                .title {
                                    color: $--my-black;
                                    width: 200px;
                                    margin: 0;
                                    padding: 0 12px;
                                }
                                .about {
                                    font-size: 12px;
                                    color: $--secondary-font-color;
                                    margin: 0;
                                    padding: 0 12px;
                                    height: 10px;
                                    // overflow: hidden;
                                }
                            }
                            .button{
                                background-color: white;
                                padding: 8px 0px;
                                button {
                                    font-size: 10px;
                                    width: 155px;
                                    margin-left: 12px;
                                    justify-self: flex-end;
                                }
                            }
                        }
                    }
                }
                .products-container::-webkit-scrollbar {
                    width: 0px;
                }
            }
        }
    }
    .section-6 {
        margin: auto;
        width: 65%;
        display: flex;
        // justify-content: center;
        .section-6-container {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            gap: 14px;
            // width: 1050px;
            // margin: 0 100px 0 0;
            img {
                width: 40%;
                transform: rotateY(180deg);
                // height: 450px;
            }
            .text-container {
                h1 {
                    font-size: 40px;
                    .vertical-text {
                        font-size: 14px;
                        top: 40px;
                        left: -40px;
                    }

                    .bold {
                        max-width: 210px;
                        margin: 0;
                        text-wrap: nowrap;
                    }
                }
                p {
                    color: $--my-black;
                    font-size: 16px;
                    font-weight: 400;
                }
                .call-btn {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 216px;
                    .primary-btn {
                        border: 1px solid var(--color-brand-logo-1, #e31e26);
                        box-shadow: 0px 0px 4px 1px #e31e26 inset;
                        transition: all 0.18s ease-in;
                        text-wrap: nowrap;
                        &:before {
                            transition: all 0.18s ease-in;
                        }
                    }
                    .call-icon {
                        background-color: $--my-red;
                        padding: 8px;
                        display: inline-grid;
                        place-items: center;
                        border-radius: 50%;
                        position: absolute;
                        transition: all 0.18s ease-in 0s;
                        left: 0;
                        z-index: -1;
                        svg {
                            path {
                                fill: #ffffff;
                            }
                        }
                    }
                }
                .call-btn:hover > .call-icon {
                    left: 100%;
                }
            }
        }
    }
    .section-7 {
        display: flex;
        justify-content: flex-end;
        padding: 80px;
        .section-7-container {
            width: 950px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            h1 {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 40px;
                text-wrap: nowrap;
                .vertical-text {
                    font-size: 12px;
                    top: 20px;
                    left: -28px;
                }
                .bold {
                    max-width: 160px;
                    text-wrap: nowrap;
                }
            }
            .connect-form {
                display: flex;
                gap: 20px;
                background: #000;
                border-radius: 180px 0 0 180px;
                padding: 10px;
                padding-right: 28px;

                img {
                    height: 340px;
                    width: 340px;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    justify-content: center;
                    input {
                        background: transparent;
                        width: 100%;
                        padding: 6px 12px;
                        border: none;
                        border-bottom: 1px solid var(--c-6-c-6-c-6, #c6c6c6);
                        outline: none;
                        color: white;
                    }
                    input:focus {
                        border-color: white;
                    }
                    .input-grp {
                        display: flex;
                        align-items: center;
                        gap: 24px;
                        input {
                            width: 100%;
                        }
                        .input {
                            display: flex;
                            flex-direction: column;
                            width: 50%;
                        }

                        .call-grp {
                            display: flex;
                            flex-direction: column;
                            width: 50%;

                            input {
                                border: none;
                                width: 100%;
                            }
                            .input {
                                width: 100%;
                                flex-direction: row;
                                border-bottom: 1px solid
                                    var(--c-6-c-6-c-6, #c6c6c6);
                                gap: 8px;
                            }
                            select {
                                background-color: transparent;
                                border: none;
                                outline: none;
                                color: white;
                                option {
                                    color: black;
                                }
                            }
                            .country-code {
                                position: relative;
                                p {
                                    margin: 0;
                                }
                                .selected-code {
                                    display: flex;
                                    gap: 8px;
                                    align-items: center;

                                    p {
                                        color: white;
                                        font-size: 16px;
                                    }
                                    img {
                                        height: 16px;
                                        width: 16px;
                                    }
                                }
                                .options {
                                    display: none;
                                    position: absolute;
                                    color: #c6c6c6;
                                    z-index: 2;
                                    p {
                                        margin: 2px 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                            .open {
                                .selected-code {
                                    img {
                                        transform: rotate(180deg);
                                    }
                                }
                                .options {
                                    display: block;
                                }
                            }
                        }
                    }
                    button {
                        border: 1px solid var(--color-brand-logo-1, #e31e26);
                        box-shadow: 0px 0px 4px 1px #e31e26 inset;
                        width: 170px;
                    }
                }
            }
        }
    }
    .section-8 {
        display: flex;
        justify-content: flex-end;
        padding: 80px;
        .section-8-container {
            width: 600px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            h1 {
                font-size: 40px;
                .vertical-text {
                    font-size: 12px;
                    top: 40px;
                    left: -48px;
                }
                .bold {
                    max-width: 230px;
                    text-wrap: nowrap;
                }
            }
            .arrows {
                display: flex;
                justify-content: flex-end;
                .reverse {
                    transform: rotate(-180deg);
                    margin-left: 10px;
                }
            }
            .client-reviews {
                min-width: 100%;
                display: flex;
                gap: 10px;
                overflow-x: scroll;
                overflow-y: unset;
                scroll-behavior: smooth;
                .review {
                    min-width: 100%;
                    display: flex;
                    gap: 40px;
                    height: fit-content;
                    img {
                        height: 100%;
                    }
                    .text {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        color: $--my-black;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 0 8px;
                        p {
                            margin: 0;
                        }
                        .company-name {
                            font-size: 14px;
                            color: $--secondary-font-color;
                        }
                    }
                }
            }
            .client-reviews::-webkit-scrollbar {
                width: 0px;
            }
        }
    }
    .section-9 {
        display: flex;
        align-items: center;
        justify-content: center;
        .section-9-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            p {
                font-size: 232px;
                color: $--my-red;
                margin: 0;
            }
            .we-loading{
                width: 25%;
                height: 200px;
                margin-right: 20px;
                margin-bottom: 30px;
            }
            .text {
                h1 {
                    color: $--my-black;
                    font-size: 54px;
                    margin: 0;
                    text-transform: uppercase;
                }
            }
            .we-text-loading{
                width: 75%;
                height: 200px;
                margin-bottom: 30px;
            }
        }
    }
}
@media (max-width: 1600px) {
    .home-main {
        .logo-bg {
            height: 240px;
            right: 40px;
        }
    }
}
@media (max-width: 1100px) {
    .home-main {
        .section-1 {
            width: 82%;
            .section-1-container {
                gap: 70px;
                .main-text {
                    div {
                        .my-svg {
                            img {
                                height: 150px;
                            }
                        }
                    }
                }
            }
        }
        .section-2, .section-3, .section-4, .section-5 {
            width: 82%;
        }
        .logo-bg {
            height: 160px;
            width: 110vw;
        }
        .section-6 {
            width: 82%;
            // .section-6-container {
            //     margin: 0 40px 0 0;
            // }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 210px;
                }
                .text {
                    h1 {
                        font-size: 48px;
                    }
                }
            }
        }
    }
}
@media (max-width: 950px) {
    .home-main {
        .section-1 {
            margin-top: 0;
            .section-1-container {
                .main-text {
                    h1 {
                        font-size: 42px;
                    }
                    .line-1 {
                        .vertical-text {
                            font-size: 9px;
                            left: -56px;
                            top: 50px;
                        }
                    }
                    .line-2 {
                        font-size: 42px;
                    }
                    @keyframes businessTagLineAnimation {
                        0% {
                            width: 0px;
                        }
                        40% {
                            width: 100px;
                        }
                        55% {
                            width: 90px;
                        }
                        85% {
                            width: 270px;
                        }
                        100% {
                            width: 260px;
                        }
                    }
                    div {
                        width: 420px;
                        .my-svg {
                            transform: translate(0%, -35%);
                            img {
                                height: 120px;
                            }
                        }
                    }
                    .gif {
                        transform: translateX(-35%);
                    }
                }
            }
        }
        .section-5 {
            .section-5-container {
                width: 100%;
                padding: 0 10px;
                margin: 0;
                gap: 12px;
                .header {
                    h1 {
                        width: unset;
                        font-size: 42px;
                        .vertical-text {
                            font-size: 8px;
                            top: 25px;
                            left: -32px;
                        }
                    }
                    .arrows {
                        display: none;
                    }
                }
                .categories-drop-down {
                    display: flex;
                }
                .loading-categories-drop-down{
                    height: 50px;
                    width: 100%;
                }
                .product-container {
                    .product-categories {
                        display: none;
                    }
                }
            }
        }
        .section-7 {
            padding: 30px;
            .section-7-container {
                .connect-form {
                    gap: 10px;
                    padding-right: 20px;
                    img {
                        height: 300px;
                        width: 300px;
                    }
                    form {
                        .input-grp {
                            gap: 12px;
                        }
                    }
                }
            }
        }
        .section-8 {
            padding: 20px;
            .section-8-container {
                width: 100%;
            }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 180px;
                }
                .text {
                    h1 {
                        font-size: 40px;
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .home-main {
        .section-1 {
            width: 70%;
            .section-1-container {
                flex-direction: column;
                gap: 32px;
                .main-text {
                    div {
                        width: 360px;
                        .call-btn {
                            margin-top: 16px;
                        }
                        .my-svg {
                            transform: translate(0%, -35%);
                        }
                    }
                    .gif {
                        margin-top: 16px;
                        transform: translateX(-50%);
                    }
                }
                .statistics {
                    flex-direction: row;
                    gap: unset;
                    justify-content: space-between;
                    div {
                        span {
                            text-align: center;
                        }
                    }
                    .loading-statistics-container{
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }
        .section-2{
            width: 70%;
            .section-2-container{
                .client-logo-container{
                    justify-content: space-evenly;
                }
            }
        }
        .section-3 {
            width: 70%;
        }
        .section-4 {
            width: 70%;
            .section-4-container {
                img {
                    display: none;
                }
                .loading-about-us-img{
                    display: none;
                }
                .content{

                    .loading-about-us-content{
                        // height: 300px;
                        width: 100%;
                    }
                }
            }
        }
        .logo-bg {
            height: 130px;
            margin-bottom: 50px;
        }
        .section-5{
            width: 70%;
        }
        .section-6 {
            width: 70%;
            .section-6-container {
                // flex-direction: column;
                // width: unset;
                // padding: 0 30px;
                // margin-top: 80px;
                // margin-right: 0;

                img{
                    display: none;
                }
            }
        }
        .section-7 {
            padding: 6px;
            padding-right: 0px;
            margin: 50px 0;
            .section-7-container {
                .connect-form {
                    padding: 6px;
                    padding-right: 10px;
                    gap: 6px;
                    img {
                        height: 240px;
                        width: 240px;
                    }
                    form {
                        .input-grp {
                            gap: 8px;
                            .call-grp {
                                gap: 0px;
                            }
                        }
                    }
                }
            }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 140px;
                }
                .text {
                    h1 {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
@media (max-width: 650px) {
    .home-main {
        .section-1 {
            width: 100%;
            padding: 0 30px;
            .section-1-container {
                .main-text {
                    gap: 0;
                    h1 {
                        font-size: 32px;
                        line-height: 50px;
                        margin: 0;
                    }
                    .line-1 {
                        .vertical-text {
                            font-size: 8px;
                            left: -50px;
                            top: 26px;
                        }
                    }
                    .line-2 {
                        font-size: 32px;
                        margin: 0;
                    }
                    @keyframes businessTagLineAnimation {
                        0% {
                            width: 0px;
                        }
                        40% {
                            width: 75px;
                        }
                        55% {
                            width: 65px;
                        }
                        85% {
                            width: 210px;
                        }
                        100% {
                            width: 200px;
                        }
                    }
                    div {
                        width: unset;
                        .my-svg {
                            display: none;
                        }
                    }
                    p {
                        width: unset;
                    }
                    .gif {
                        margin-top: 16px;
                        transform: translateX(-12%);
                    }
                }
            }
        }
        .section-2 {
            width: 100%;
            padding: 20px;
            margin: 50px auto;

            .section-2-container {
                width: 100%;
                h1 {
                    font-size: 32px;
                    line-height: 44px;
                    .vertical-text {
                        font-size: 12px;
                        top: 23px;
                        left: -44px;
                    }
                    .bold {
                        max-width: 230px;
                    }
                }
                .client-logo-container {
                    display: none;
                }
                .client-logo-swiper {
                    display: block;
                    margin-top: 16px;
                    width: 100%;
                }
            }
        }
        .section-3 {
            width: 100%;
            margin: auto;
            .section-3-container {
                padding: 8px;
                h1 {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 32px;
                    line-height: 44px;
                    .vertical-text {
                        font-size: 8px;
                        top: 2px;
                        left: -26px;
                    }
                    .bold {
                        max-width: 180px;
                    }
                }
                .stratergies {
                    flex-direction: column;
                    gap: 16px;
                    width: unset;
                    .stratergy {
                        .title {
                            display: flex;
                            gap: 8px;
                            p {
                                font-size: 24px;
                            }
                            .red {
                                font-size: 24px;
                            }
                        }
                    }

                    .loading-strat{
                        height: 100px;
                        width: 99%;
                    }
                }
            }
        }
        .logo-bg {
            height: 100px;
            right: 25px;
            margin-top: 50px;
        }
        .section-4 {
            margin: 50px auto;
            width: 100%;
            .section-4-container{
                padding: 10px;
            }
        }
        .section-5 {
            width: 100%;
            .section-5-container {
                .header {
                    h1 {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
        }
        .section-6 {
            width: 100%;
            .section-6-container {
                padding: 0;
                .text-container {
                    margin-left: 10px;
                    h1 {
                        font-size: 32px;
                        .vertical-text {
                            font-size: 12px;
                            top: 32px;
                            left: -32px;
                        }
                        .bold {
                            max-width: 170px;
                        }
                    }
                }
            }
        }
        .section-7 {
            padding: 0;
            .section-7-container {
                background-color: #000;
                padding: 50px 10px;
                .section-heading {
                    color: white;
                    align-self: flex-start;
                }
                .connect-form {
                    border-radius: 0px;
                    img {
                        display: none;
                    }
                }
            }
        }
        .section-8 {
            .section-8-container {
                .client-reviews {
                    .review {
                        gap: 12px;
                        .text {
                            font-size: 12px;
                            .company-name {
                                font-size: 10px;
                                color: $--secondary-font-color;
                            }
                        }
                    }
                }
            }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 80px;
                }
                .text {
                    h1 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .home-main {
        .section-1 {
            padding: 0 0 0 20px;
            .section-1-container {
                .main-text {
                    gap: 0;
                    h1 {
                        font-size: 32px;
                        line-height: 50px;
                        margin: 0;
                    }
                    .line-1 {
                        .vertical-text {
                            font-size: 8px;
                            left: -50px;
                            top: 26px;
                        }
                    }
                    div {
                        .my-svg {
                            display: none;
                        }
                    }
                    p {
                        width: unset;
                    }
                    .gif {
                        margin-top: 16px;
                        transform: translateX(-12%);
                    }
                }
            }
        }
        .logo-bg {
            height: 70px;
            margin-bottom: 0;
        }
        .section-6 {
            .section-6-container {
                padding: 0;
                .text-container {
                    h1 {
                        font-size: 28px;
                        margin-left: 30px;
                        .vertical-text {
                            top: 26px;
                            left: -30px;
                            font-size: 12px;
                        }
                        .bold {
                            max-width: 150px;
                        }
                    }
                    p {
                        padding: 0 20px;
                    }
                    .call-btn {
                        margin-left: 20px;
                    }
                }
            }
        }
        .section-7 {
            .section-7-container {
                h1 {
                    font-size: 34px;
                    .vertical-text {
                        top: 12px;
                    }
                }
                .connect-form {
                    width: 100%;
                    form {
                        width: 100%;
                        gap: 8px;
                        .input-grp {
                            flex-direction: column;
                            .input {
                                width: 100%;
                            }
                            input {
                                width: 100%;
                            }
                            .call-grp {
                                width: 100%;
                            }
                        }
                        button {
                            margin-top: 24px;
                        }
                    }
                }
            }
        }
        .section-8 {
            padding: 12px;
            .section-8-container {
                .client-reviews {
                    .review {
                        gap: 8px;
                    }
                }
            }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 70px;
                }
                .text {
                    h1 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
@media (max-width: 360px) {
    .home-main {
        .section-1 {
            padding: 0;
            .section-1-container {
                .main-text {
                    padding-left: 20px;
                    h1 {
                        font-size: 28px;
                        line-height: 38px;
                        margin: 0;
                    }
                    .line-1 {
                        .vertical-text {
                            font-size: 10px;
                            left: -60px;
                            top: 36px;
                        }
                    }
                    .line-2 {
                        font-size: 28px;
                    }
                    @keyframes businessTagLineAnimation {
                        0% {
                            width: 0px;
                        }
                        40% {
                            width: 70px;
                        }
                        55% {
                            width: 60px;
                        }
                        85% {
                            width: 185px;
                        }
                        100% {
                            width: 175px;
                        }
                    }
                }
                .statistics {
                    div {
                        .number {
                            font-size: 36px;
                        }
                        .text {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .section-2 {
            .section-2-container {
                h1 {
                    font-size: 28px;
                    margin-left: 20px;
                }
            }
        }
        .section-9 {
            .section-9-container {
                p {
                    font-size: 60px;
                }
                .text {
                    h1 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
