@import "/src/variables";
@import "/src/index.scss";

.product-table{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    
    .product{
        padding: 12px;
        border: 1px solid #C7C7C7;
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-height: 150px;
        cursor: pointer;
        transition: all 0.2s;
        h2{
            color: $--my-black;
            font-size: 24px;
            margin: 0;
        }
        p{
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: $--secondary-font-color;
        }
    }
    .product:hover{
        background-color: $--my-red;
        transform: scale(1.1);
        h2{
            color: #F1F1F1;
        }
        p{
            color: #F1F1F1 !important;
        }
    }
}
@media (max-width: 1600px){
    .product-table{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (max-width: 1000px){
    .product-table{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 650px){
    .product-table{
        grid-template-columns: 1fr;
    }
}