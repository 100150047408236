@import "../../variables";
@import '/src/index.scss';

.header-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 180px;
    background: $--background-color;
    border-bottom: 1px solid var(--color-outline-outline-2, #C7C7CD);
    position: fixed;
    z-index: 1;
    width: 100vw;
    top: 0;

    .back-drop{
        background: #00000044;
        z-index: 2;
        height: 100vh;
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    .menu{
        display: flex;
        align-items: center;
        gap: 24px;

        a{
            text-decoration: none;
        }

        .menu-item{
            color: $--my-black;
            transition: all 0.5s;
            font-size: 16px;
            font-weight: 400;
            cursor: pointer;
            text-wrap: nowrap;
        }
        .menu-item:hover{
            color: $--my-red;
            border-bottom: 1px solid $--my-black;
            border-color: $--my-red;
        }
        img{
            height: 28px;
            cursor: pointer;
        }
        .user{
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;
            cursor: pointer;
            img{
                height: 24px;
            }
            p{
                margin: 0;
                text-wrap: nowrap
            }
            .user-options{
                position: absolute;
                top: 40px;
                display: flex;
                flex-direction: column;
                gap: 12px;
                justify-content: center;
                background-color: #FFFFFF;
                padding: 12px;
                cursor: auto;
                .option{
                    display: flex;
                    gap: 12px;
                    color: $--my-black;
                    cursor: pointer;
                }
                z-index: 2;
            }
        }
    }
    .mobile-icons{
        display: none;
    }

    .whatsapp{
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
        transform: scale(0);
        animation: wpAnimation 0.2s ease-in 0s 1 forwards;
    }
    @keyframes wpAnimation {
        0%{
            transform: scale(0);
        }100%{
            transform: scale(1);
        }
    }
    .whatsapp:hover{
        transform: scale(1.05);
    }
}
@media (max-width: 1100px){
    .header-main{
        padding: 30px 90px;
    }
}
@media (max-width: 850px){
    .header-main{
        padding: 20px;
    }
}
@media (max-width: 750px){
    .header-main{
        .menu{
            display: none;
        }
        .mobile-icons{
            display: flex;
            gap: 12px;

            img{
                height: 28px;
                width: 28px
            }
        }
    }
}
@media (max-width: 650px){
    .header-main{
        padding: 20px;
    }
}