@import "/src/variables";
@import "/src/index.scss";

.welcome-main{
    display: flex;
    margin-top: -105px;
    padding: 180px 180px 0 180px;
}

@media (max-width: 750px){
    .welcome-main{
        padding: 160px 80px 0 80px;
    }
}

@media (max-width: 650px){
    .welcome-main{
        padding: 120px 60px 0 60px;
    }
}

@media (max-width: 550px){
    .welcome-main{
        padding: 120px 40px 0 40px;
    }
}