@import "/src/variables";
@import "/src/index.scss";

.questions{
    width: 100%;
    display: flex;
    flex-direction: column;
    .arrow {
        position: absolute;
        top: 120px;
    }
    h3{
        color: $--my-black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    p{
        color: $--secondary-font-color;
        font-size: 20px;
        font-weight: 400;
    }
    .form-error{
        font-size: 12px;
    }
    .session-questions-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 14px;
        p{
            margin: 0;
        }
        label {
            color: #344054;
            font-size: 16px;
            font-weight: 600;
        }
        .information{
            margin: 0 !important;
            color: #7C7C7C;
            font-size: 12px;
            font-weight: 400;
        }
        input,
        select {
            padding: 12px 16px;
            border-radius: var(--spacing-0, 4px);
            border: 1px solid #d0d5dd;
            background: var(--white, #fff);
            font-size: 16px;
            font-weight: 400;
            outline: none;
        }
        input {
            width: 100%;
        }
        .input-grp {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 4px;
            width: 100%;

            .call-grp {
                display: flex;
                select {
                    border-right: none;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                input {
                    border-left: none;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }
            .arrow {
                width: 36px;
                height: 36px;
                padding: 0;
                display: inline-grid;
                place-items: center;
                border-radius: 50%;
                svg {
                    transform: rotate(-180deg);
                }
                &:before {
                    background-color: $--my-black;
                }
                &:hover {
                    border-color: $--my-black;

                    svg {
                        path {
                            stroke: white;
                        }
                    }
                }
            }
            p {
                margin-bottom: 6px;
            }
            .choices {
                display: flex;
                gap: 12px;
                flex-wrap: wrap;
                button {
                    &:before {
                        background-color: $--my-black;
                    }
                    &:hover {
                        border-color: $--my-black;
                    }
                }
                .selected {
                    background-color: $--my-black;
                    color: white;
                }
            }
        }
    }
    .submit-btn{
        margin: 20px 0;
    }
    button{
        width: fit-content;
        align-self: flex-end;
    }
}
@media (max-width: 750px){
    .questions{
        .arrow{
            top: 110px;
        }
        margin-top: 50px;

        .session-questions-container{
            grid-template-columns: 1fr;
        }
    }
}
@media (max-width: 650px){
    .products-select{
        margin-top: 70px;
        h3{
            font-size: 32px;
        }
    }
}