@import '/src/variables';
@import '/src/index.scss';

.footer-main{
    padding: 60px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    border-top: 1px solid var(--color-outline-outline-2, #C7C7CD);

    .footer-section{
        display: flex;
        flex-direction: column;
        gap: 30px;

        h4{
            width: 210px;
            border-bottom: 2px solid $--my-red;
            text-align: right;
            font-size: 24px;
            font-weight: 700;
            color: $--my-black;
        }
        .contact-info{
            display: flex;
            gap: 30px;
            font-size: 14px;
            font-weight: 400;
            padding-left: 90px;
            
            .contact-no{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
                a{
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    text-decoration: none;
                    color: $--my-black;
                }
                .social {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 12px;
                    .logos {
                        display: flex;
                        gap: 12px;

                        a {
                            border-radius: 50%;
                            height: 32px;
                            width: 32px;
                            display: grid;
                            place-items: center;
                            cursor: pointer;
                            svg {
                                height: 20px;
                                width: 20px;
                                path {
                                    fill: white;
                                }
                            }
                        }
                        .instagram {
                            background: #ea3e27;
                        }
                        .facebook {
                            background: #1877f2;
                        }
                        .linkedin {
                            background: #2867b2;
                        }
                        .twitter {
                            background: #1da1f2;
                        }
                        .whatsapp {
                            background: #60d66a;
                        }
                    }
                }
            }
            .address{
                max-width: 280px;
                h5{
                    font-weight: 700;
                    font-size: 14px;
                    margin-bottom: 12px;
                }
            }
        }
        .about-items{
            display: flex;
            // justify-content: center;
            flex-wrap: wrap;
            gap: 24px;
            padding-left: 90px;

            .about-link{
                font-size: 16px;
                font-weight: 400;
                color: $--my-black;
                text-decoration: none;
                transition: all 0.5s;
                cursor:default;
            }
            .about-link:hover{
                color: $--my-red;
            }
        }
    }
}

@media (max-width: 1000px){
    .footer-main{
        padding: 50px;
        .footer-section{
            .contact-info, .about-items{
                padding-left: 20px;
            }
        }
    }
}
@media (max-width: 650px){
    .footer-main{
        padding: 30px;
        gap: 40px;
        .footer-section{
            gap: 20px;
            h4{
                text-align: left;
                width: 100%;
            }
            .contact-info{
                flex-direction: column;
                padding-left: 20px;
                .address{
                    max-width: unset;
                }
            }
            .about-items{
                gap: 20px;
            }
        }
    }
}
@media (max-width: 400px){
    .footer-main{
        padding: 20px;
        .footer-section{
            .contact-info{
                padding-left: 20px;
            }
        }
    }
}