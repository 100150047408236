@import "/src/variables";
@import "/src/index.scss";

.add-to-cart-main{
    width: 400px;
    border-radius: 12px;
    overflow: hidden;
    .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--surface-surface-primary, #F1F1F1);
        padding: var(--spacing-3, 24px) 0px;
        position: relative;
        
        .cross{
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
        p{
            color: #198754;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px;

        p{
            color: $--secondary-font-color;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
        .products-container{
            display: flex;
            flex-direction: column;
            .product-info{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                
                .product-name{
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    img{
                        height: 50px;
                        width: 50px;
                        border: 1px solid $--secondary-font-color;
                    }
                    h3{
                        display: inline-block;
                        font-size: 16px;
                        font-weight: 600;
                        width: 120px;
                    }
                }
                .price{
                    p{
                        color: $--my-black;
                        font-size: 18px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    .remove{
                        text-align: right;
                        font-size: 14px;
                        font-weight: 400;
                        text-decoration-line: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        .buttons{
            display: flex;
            gap: 16px;
            button{
                width: 100%;
            }
        }
    }
}