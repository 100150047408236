@import "/src/variables";
@import "/src/index.scss";

.cart-questions-main{
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 20px;
    width: 500px;
    max-width: 90vw;

    h2 {
        margin: 0;
        color: var(--gray-900, #101828);
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 8px;
    }
    .input-grp {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        label {
            color: #344054;
            font-size: 16px;
            font-weight: 600;
        }
        .information{
            margin: 0 !important;
            color: #7C7C7C;
            font-size: 12px;
            font-weight: 400;
        }
        input,
        select {
            padding: 12px 16px;
            border-radius: var(--spacing-0, 4px);
            border: 1px solid #d0d5dd;
            background: var(--white, #fff);
            font-size: 16px;
            font-weight: 400;
            outline: none;
        }
        input {
            width: 100%;
        }
    }
}