@import "/src/variables";
@import "/src/index.scss";

.forgot-password-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 12px;
    background: var(--base-white, #fff);
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04),
        0px 20px 24px -4px rgba(16, 24, 40, 0.1);
    position: relative;
    padding: 20px;
    width: 320px;

    .cross {
        height: 24px;
        width: 24px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    h2 {
        margin: 0;
        color: var(--gray-900, #101828);
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 8px;
    }

    p {
        margin: 0;
    }

    input {
        border-radius: var(--spacing-0, 4px);
        border: 1px solid #d0d5dd;
        background: var(--white, #fff);
        padding: 12px var(--spacing-2, 16px);
        outline: none;
        width: 100%;
    }
}
