@import "/src/variables";
@import "/src/index.scss";

.schedule-call-main {
    display: flex;
    margin-top: -105px;
    .close {
        width: 36px;
        height: 36px;
        padding: 0;
        display: inline-grid;
        place-items: center;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #F1F1F1;
        svg {
            transform: rotate(-180deg);
        }
        &:before {
            background-color: $--my-black;
        }
        &:hover {
            border-color: $--my-black;

            svg {
                path {
                    fill: #F1F1F1;
                    stroke: #F1F1F1;
                }
            }
        }
    }
    .section-1 {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 40px;
        color: $--my-black;
        height: 100vh;
        overflow-y: scroll;

        h1 {
            font-size: 64px;
            font-weight: 400;
            text-transform: capitalize;
        }
        h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 4px;
        }
        .description {
            color: $--secondary-font-color;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 24px;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 16px;

            label {
                color: #344054;
                font-size: 16px;
                font-weight: 600;
            }
            .information{
                margin: 0 !important;
                color: #7C7C7C;
                font-size: 12px;
                font-weight: 400;
            }
            input,
            select {
                padding: 12px 16px;
                border-radius: var(--spacing-0, 4px);
                border: 1px solid #d0d5dd;
                background: var(--white, #fff);
                font-size: 16px;
                font-weight: 400;
                outline: none;
            }
            input {
                width: 100%;
            }
            .input-grp {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;

                .call-grp {
                    display: flex;
                    select {
                        border-right: none;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                    input {
                        border-left: none;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }
                }
                .arrow {
                    width: 36px;
                    height: 36px;
                    padding: 0;
                    display: inline-grid;
                    place-items: center;
                    border-radius: 50%;
                    svg {
                        transform: rotate(-180deg);
                    }
                    &:before {
                        background-color: $--my-black;
                    }
                    &:hover {
                        border-color: $--my-black;

                        svg {
                            path {
                                stroke: white;
                            }
                        }
                    }
                }
                p {
                    margin-bottom: 6px;
                }
                .choices {
                    display: flex;
                    gap: 12px;
                    flex-wrap: wrap;
                    button {
                        &:before {
                            background-color: $--my-black;
                        }
                        &:hover {
                            border-color: $--my-black;
                        }
                    }
                    .selected {
                        background-color: $--my-black;
                        color: white;
                    }
                }
            }
            .session-questions-container{
                display: flex;
                flex-direction: column;
                gap: 14px;
                p{
                    margin: 0;
                }
                // .session-questions{
                //     margin-left: 16px;
                // }
            }
            .company-details {
                display: flex;
                gap: 16px;
                width: 100%;
            }
            .file-upload {
                .title {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                }
                .description {
                    color: $--secondary-font-color;
                    font-size: 12px;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                .file-upload-div {
                    display: grid;
                    place-items: center;
                    min-height: 100px;
                    width: min(100%,318px);
                    border-radius: var(--spacing-2, 16px);
                    border: 2px dashed var(--color-outline-outline-2, #c7c7cd);
                    background: var(--color-grey-50, #f1f1f1);
                    font-size: 14px;
                    padding: 16px;
                    color: var(--grey-05, #242634);
                    text-align: center;
                }
            }
            .submit-btn {
                width: 110px;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #828282;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: #4b4a4a;
    }
    .section-2 {
        width: 50%;
        height: 100vh;
        overflow-y: scroll;
        background: var(--color-grey-50, #f1f1f1);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;

        .contact-container {
            display: flex;
            flex-direction: column;
            gap: 32px;
            padding: 60px 30px 0px 30px;

            .detail {
                span {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    svg {
                        height: 24px;
                        width: 24px;
                        path {
                            fill: $--my-red;
                        }
                    }
                    p {
                        margin: 0;
                        color: #101828;
                        font-weight: 700;
                        font-size: 24px;
                    }
                }
                a {
                    margin: 0;
                    color: #475467;
                    font-size: 16px;
                    font-weight: 400;
                    text-decoration: none;
                }
            }
            .address-details {
                display: flex;
                flex-direction: column;
                gap: 12px;

                .address {
                    h5 {
                        margin-bottom: 12px;
                        color: #475467;
                        font-size: 16px;
                        font-weight: 700;
                    }
                    p {
                        margin-bottom: 0;
                        color: #475467;
                    }
                }
            }
        }
        .client-review {
            margin: 0 30px;
            background-color: $--background-color;
            padding: var(--spacing-2, 16px) var(--spacing-3, 24px);
            border-radius: 4px;
            h3{
                margin-bottom: 12px;
                font-size: 32px;
                font-weight: 400;
                text-align: center;
            }
            .review {
                min-width: 100%;
                display: flex;
                gap: 40px;
                height: fit-content;
                img {
                    height: 100%;
                }
                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    color: $--my-black;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0 8px;
                    p {
                        margin: 0;
                    }
                    .company-name {
                        font-size: 14px;
                        color: $--secondary-font-color;
                    }
                }
            }
        }
        .clients-companies{
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: var(--spacing-3, 24px) 30px;
            background: $--my-black;

            h3{
                text-align: center;
                color: var(--text-text-invert, #F1F1F1);
                font-size: 24px;
                font-weight: 600;
            }
            .client-logo-swiper {
                width: 100%;
                z-index: 0;
                
                .swiper-wrapper {
                    margin-left: 35%;
                    width: fit-content;
                    align-items: center;
                    img{
                        width: 70px;
                        max-height: 150px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1350px){
    .schedule-call-main{
        .section-1{
            form{
                .company-details{
                    flex-direction: column;
                }
            }
        }
    }
}
@media (max-width: 950px){
    .schedule-call-main{
        flex-direction: column;
        .section-1{
            width: 100%;
            height: unset;
            overflow-y: unset;
            form{
                .company-details{
                    flex-direction: row;
                }
            }
        }
        .section-2{
            width: 100%;
            overflow-y: visible;
            height: unset;
            .contact-container{
                display: none;
            }
            .client-review{
                display: none;
            }
        }
    }
}
@media (max-width: 650px){
    .schedule-call-main{
        .section-1{
            padding: 20px;
            form{
                .company-details{
                    flex-direction: column;
                }
            }
        }
    }
}