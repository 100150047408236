@import "/src/variables";
@import "/src/index.scss";

.support-main{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3, 24px);
    
    button{
        width: fit-content;
    }
    .ticket{
        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 8px;
        border-radius: var(--spacing-1, 8px);
        border: 1px solid var(--gray-200, #EAECF0);
        background: white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);

        .time-status{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time{
                color: #202020;
                font-size: 18px;
                font-weight: 600;
            }
            .status{
             padding: var(--spacing-0, 4px) 18px;
             border-radius: var(--spacing-0, 4px);
             font-size: 16px;
             font-weight: 600;
            }
            .sent{
                background: #F2F4FE;
                border: 1px solid #D4D6E0;
                color: #1877F2;
            }
            .completed{
                background: #E2F6E7;
                border: 1px solid #CEE2D3;
                color: #06B82F;
            }
        }
        p{
            margin: 0;
            color: #8C8F96;
            font-size: 12px;
            font-weight: 400;
        }
    }
}