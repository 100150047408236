@import "/src/variables";
@import "/src/index.scss";

.contact-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    
    .heading{
        padding: 20px 100px;
        h1{
            margin-bottom: 0;
            color: $--my-black;
            font-size: 40px;
            font-weight: 600;
            text-align: center;
        }
        p{
            margin: 0;
            color: $--secondary-font-color;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }
    }
    .content{
        display: flex;
        gap: 30px;
        padding: 0px 20px 0px 20px;
        img{
            width: 50%;
        }
        form{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 48px;
            .heading{
                padding: 0;
                h2{
                    font-size: 36px;
                    color: #10182B;
                    font-weight: 600;
                    text-align: left;
                }
                p{
                    color: #475467;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0;
                    text-align: left;
                }
            }
            .inputs{
                display: flex;
                flex-direction: column;
                gap: var(--spacing-3, 24px);
                width: 100%;
                
                .input-grp{
                    display: flex;
                    flex-direction: column;
                    label,p{
                        margin: 0;
                    }
                    label{
                        color: #344054;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    input, textarea{
                        padding: 12px var(--spacing-2, 16px);
                        border-radius: var(--spacing-0, 4px);
                        border: 1px solid #D0D5DD;
                        background: var(--white, #FFF);
                        outline: none;
                    }
                    textarea{
                        height: 130px;
                        resize: none;
                    }
                }
                .privacy-check{
                    display: flex;
                    flex-direction: column;
                    
                    .check-box-container{
                        display: flex;
                        gap: 4px;
                        align-items: center;
                        .check-box{
                            cursor: pointer;
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            border: 1px solid $--my-black;
                            border-radius: 4px;
                            display: grid;
                            place-items: center;
                        }
                        .checked{
                            background-color: $--my-black;
                            svg{
                                path{
                                    fill: #FFF;
                                }
                            }
                        }
                        p{
                            margin: 0;
                            color: #475467;
                            font-size: 16px;
                            font-weight: 400;
                            
                            a{
                                color: #475467;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact-info{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        width: 100%;
        background: #F9FAFB;
        padding: 60px 0;
        .section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            a, p{
                text-decoration: none;
                margin: 0;
                font-size: 16px;
                text-align: center;
                color: #475467;
            }
            svg{
                path{
                    fill: $--my-red;
                }
            }
            .title{
                font-size: 24px;
                color: #101828;
            }
            .text{
                font-weight: 400;
            }
            .office{
                max-width: 350px;
            }
        }
    }
}

@media (max-width: 900px){
    .contact-main{
        .heading{
            padding: 20px;
        }
    }
}
@media (max-width: 750px){
    .contact-main{
        gap: 30px;
        .content{
            flex-direction: column;
            padding: 0 20px;
            img,form{
                width: 100%;
            }
        }
    }
}
@media (max-width: 600px){
    .contact-main{
        gap: 30px;
        .contact-info{
            flex-direction: column;
            align-items: center;
            padding: 30px;
        }
    }
}