@import "./variables";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $--primary-font-family;
}

body{
  overflow-x: hidden !important;
}

.primary-btn {
  display: inline-block;
  padding: 8px 24px;
  background-color: $--background-color;
  border: 1px solid $--my-black;
  border-radius: 60px;
  color: $--my-black;
  font-weight: 600;
  font-size: 16px;
  transition: all .08s ease-in;
  transition-delay: 0.1s;
  position: relative;
  overflow: hidden;
  z-index: 0;
  outline: none;
  min-height: 32px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $--my-red;
    transition: all .08s ease-in;
    transition-delay: 0.1s;
    border-radius: 60px;
    border: none;
    z-index: -1;
  }

  &:hover {
    color:  $--background-color;
    border-color: $--my-red;
    transition: all 0.08s ease-in;
    transition-delay: 0.18s;
    &:before {
      width: 100%;
    }
  }
}
.bold{
  font-weight: 700;
}
.primary{
  padding: 10px 18px;
  border-radius: 8px;
  background: $--my-red;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
}
.primary:hover{
  background-color: #AB1318;
}
.secondary{
  padding: 10px 18px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #C7C7CD;
  background-color: white;
}
.secondary:hover{
  background-color: #AB1318;
  color: #FFFFFF;
}
.secondary[disabled]{
  background-color: white;
  color: #C7C7CD;
  cursor: not-allowed;
}
.arrow {
  width: 36px;
  height: 36px;
  padding: 0;
  display: inline-grid;
  place-items: center;
  border-radius: 50%;
  svg {
      transform: rotate(-180deg);
  }
  &:before {
      background-color: $--my-black;
  }
  &:hover {
      border-color: $--my-black;

      svg{
          path{
              stroke: white;
          }
      }
  }
}
.form-error{
  margin: 0;
  font-size: 12px;
  color: red !important;
  text-align: left !important;
  align-self: flex-start !important;
}
.form-success{
  margin: 0;
  font-size: 12px;
  color: green !important;
  text-align: left !important;
  align-self: flex-start !important;
}
.empty{
  color: $--my-black;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin: auto;
  text-align: center;
}
.client-logo-swiper {
  // display: none;
  width: 80vw;
  margin: auto;
  z-index: 0;
  position: relative;
  // margin-left: 20%;
  
  .swiper-wrapper {
      margin: auto;
      width: fit-content;
      align-items: center;
      .client{
          height: 85px;
          width: 85px;
          border-radius: 5px;
          border: 0.487px solid #d6d6d6;
          display: flex;
          align-items: center;
          justify-content: center;
          // padding: 10px;
          background-color: #F7F7F7;
          
          img {
              max-width: 100%;
              width: 75px;
              height: 75px;
              max-height: 100%;
              object-fit: contain;
              mix-blend-mode: multiply;
          }
      }
  }
}
.continue-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  gap: 6px;
  width: 75px;
  border-bottom: 1px solid $--my-black;
  cursor: pointer;
  transition: all 0.5s;
}
.continue-button:hover{
  color: $--my-red;
  border-bottom: 1px solid $--my-red;
  width: 95px;
}
.component-loading{
  background: linear-gradient(to right, #F0F0F0, #d9d9d9, #F0F0F0);
  background-size: 200%;
  animation: loading 1s infinite;
}
@keyframes loading {
  0% {
      background-position: -100%;
  }
  100% {
      background-position: 100%;
  }
}