@import "/src/variables";
@import "/src/index.scss";

.introduction{
    width: 100%;
    h3{
        color: $--my-black;
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        input,
        select {
            padding: 8px 16px;
            // border-radius: var(--spacing-0, 4px);
            border: none;
            border-bottom: 1px solid #d0d5dd;
            background: var(--white, #fff);
            font-size: 16px;
            font-weight: 400;
            outline: none;
        }
        input {
            width: 100%;
        }
        input.active, select.active{
            border-bottom: 1px solid $--my-black;
        }
        .input-grp {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;

            .call-grp {
                display: flex;
                select {
                    border-right: none;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                input {
                    border-left: none;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }
        }
        .input-container {
            display: flex;
            gap: 16px;
            width: 100%;
        }
    }
    .continue-button{
        margin-top: 80px;
    }
    button{
        width: fit-content;
        align-self: flex-end;
    }
}

@media (max-width: 850px){
    .introduction{
        form{
            .input-container{
                flex-direction: column;
            }
        }
    }
}
@media (max-width: 650px){
    .introduction{
        h3{
            font-size: 32px;
        }
    }
}