@import "/src/variables";
@import "/src/index.scss";

.export-preview-main{
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
    border-radius: 0px;
    p{
        margin: 0;   
        color: $--secondary-font-color;
        font-size: 18px;
        font-weight: 600;
    }
    .preview-window{
        max-height: 50vh;
        width: min(90vw, 600px);
        overflow-y: scroll;
        border: 15px solid #f1f1f1;
        position: relative;

        span{
            position: sticky;
            display: inline-block;
            top: 0;
            left: 0;
            padding: 6px 12px;
            color: $--my-black;
            font-size: 14px;
            font-weight: 400;
            background: var(--color-grey-200, #D3D3D3);
        }
        img{
            width: calc(100% - 30px);
        }
    }
    .buttons{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
    }
}