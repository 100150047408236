.App{
    margin-top: 105px;
}
@media(max-width: 850px){
    .App{
        margin-top: 85px;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: You can adjust the margin to control spacing */
}
